import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { StorageKeys } from "../constants";
import { getWithExpiry, removeStorageItem } from "./storage";
import { refreshAccessToken } from "../Utils";

const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer",
  },
});

axiosInstance.interceptors.request.use(
  async (value: AxiosRequestConfig) => {
    const newValue = { ...value };
    newValue.url = `${newValue?.baseURL}${newValue?.url}`;

    const token = getWithExpiry(StorageKeys.TOKEN_KEY);

    if (token) {
      newValue.headers = {
        ...newValue.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    const userData = getWithExpiry(StorageKeys.PROFILE);
    const blokcustomerid = userData?.id;

    if (blokcustomerid) {
      newValue.headers = {
        ...newValue.headers,
        blokcustomerid,
      };
    }

    return newValue;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (res) => Promise.resolve(res.data),
  (err) => {
    if (
      err?.response?.status === 401 ||
      err?.response?.data === "UNAUTHORIZED"
    ) {
      // removeStorageItem(StorageKeys.TOKEN_KEY);
      const token = getWithExpiry(StorageKeys.TOKEN_KEY);
      const refreshToken = getWithExpiry(StorageKeys.REFRESH_TOKEN);
      if (token && refreshToken) {
        refreshAccessToken(
          () => null,
          () => {
            removeStorageItem(StorageKeys.TOKEN_KEY);
          }
        );
      }
      // Auth.onSignOut()
    }

    console.log(
      `[Axios] [STATUS]: ${err.response?.status} [METHOD]: ${err.config.method} [URL]: ${err.config.url}`
    );

    return Promise.reject(err?.response?.data);
  }
);

export default axiosInstance;
