import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
  transitions: {
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      // most basic recommended timing
      standard: 500,
      // this is to be used in complex animations
      complex: 375,
      // recommended when something is entering screen
      enteringScreen: 225,
      // recommended when something is leaving screen
      leavingScreen: 195,
    },
  },
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#ffffff",
    },
    error: {
      main: "#E93933",
    },
    success: {
      main: "#00866B",
    },

    info: {
      main: "#484848",
    },
  },
  additionalColors: {
    grey: "#343434",
    lightGreyBackground: "#FAFAFA",
    appBarGreyBackground: "#F8F8F8",
    footerSubMenu: "#919191",
    greyScale4: "#E8E8E8",
    coreGrey30: "#D9D9D9",
    coreGrey40: "#CACACA",
    coreGrey60: "#767676",
    coreGrey80: "#171717",
    blueGrey: "#D2E4F3",
    greenTransparent10: "rgba(0, 134, 107, 0.10)",
  },
  typography: {
    fontFamily: "Suisse Intl, serif",
    h1: {
      fontSize: 48,
      fontWeight: 500,
    },
    h2: {
      fontSize: 36,
      fontWeight: 700,
      textTransform: "uppercase",
      "@media (max-width:899px)": {
        fontSize: 36,
      },
    },
    h3: {
      fontSize: 32,
      fontWeight: 500,
      lineHeight: 1.2,
    },
    h5: {
      fontSize: 32,
      lineHeight: 1.1,
      fontWeight: 500,
    },
    h6: {
      fontSize: 24,
      fontWeight: 500,
      lineHeight: "28px",
    },
    body1: {
      fontFamily: "Suisse Intl, serif",
      fontSize: 16,
      fontWeight: 400,
      lineHeight: "20px",
      color: "rgb(69 69 69)",
      "@media (max-width:899px)": {
        fontSize: 15,
      },
    },
    body2: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: 1.8,
    },
    subtitle1: {
      fontFamily: "Suisse Intl, serif",
      fontSize: 25,
      fontWeight: 400,
      lineHeight: 1.95,
      "@media (max-width:899px)": {
        lineHeight: 1.65,
        fontSize: 15,
      },
    },
    subtitle2: {
      fontSize: 14,
      color: "rgba(0, 0, 0, 0.6)",
      fontWeight: 300,
      lineHeight: 1.66,
      margin: "10px 0",
      "@media (max-width:899px)": {
        fontSize: 12,
      },
    },
    caption: {
      fontSize: 11,
      color: "#767676",
      fontWeight: 400,
      lineHeight: "12px",
    },
    badge: {
      fontFamily: "Suisse Intl, serif",
      fontSize: 9,
      fontWeight: 500,
      lineHeight: "10px",
      letterSpacing: "0.54px",
      textTransform: "uppercase",
      color: "#484848",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          borderRadius: 0,
          paddingX: "16px",
          paddingTop: 12,
          paddingBottom: 12,
          fontSize: 18,
          lineHeight: "22px",
          fontWeight: 400,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 20,
          "@media (max-width:600px)": {
            fontSize: 20,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          "&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active":
            {
              "-webkit-box-shadow": "0 0 0 30px #FAFAFA inset !important",
            },
          "&.Mui-disabled": {
            color: "rgba(0, 0, 0, 0.6)",
            "-webkit-text-fill-color": "rgba(0, 0, 0, 0.6)",
          },
          "@media (max-width:600px)": {
            fontSize: 18,
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          justifyContent: "flex-start",
          "& .MuiCheckbox-root": {
            paddingTop: 0,
            paddingRight: "8px",
            paddingBottom: 0,
          },
        },
        label: {
          fontSize: 14,
          fontWeight: 500,
          lineHeight: "14px",
          paddingRight: "5px",
          height: "100%",
          color: "#000000",
          "@media (max-width:600px)": {
            fontSize: 12,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          "&:focus": {
            background: "none",
          },
        },
      },
    },

    MuiTypography: {
      defaultProps: {
        variantMapping: {
          // Map the new variant to render a <caption> by default
          badge: "p",
          caption: "p",
        },
      },
    },
  },
});

export default theme;
