import { SnackbarOrigin } from "@mui/material";

export const snackbarDesktop: SnackbarOrigin = {
  vertical: "top",
  horizontal: "right",
};
export const snackbarMobile: SnackbarOrigin = {
  vertical: "bottom",
  horizontal: "center",
};

export enum StepEndpoints {
  STEP1 = `/signup/step1`,
  STEP1_SELECT_PRODUCT = `/signup/step1-product-selection`,
  STEP2 = "/signup/step2",
  STEP3 = "/signup/step3",
  STEP3_EXISTING = "/signup/step3-existing",
  OFFER = "/signup/offer",
  CARDS = "/signup/stored_cards",
  SITES = "/sites",
  CHECKOUT_STATUS = "/signup",
  LOGIN = "/login",
  PROFILE = "/account/me",
  ADD_CARD = "/account/card",
  CHECKOUT = "/shop/checkout-combined",
  CHECK_DISCOUNT_CODE = "/discounts",
  GET_MEMBERSHIPS = "/account/myMemberships?size=1000&page=1",
  REDEEM_GIFT_CARD = "/account/redeemGiftCard",
  AUTO_APPLIED_DISCOUNT = "/discounts/autoApplied",
  ATU_PRODUCTS = "/shop/autoTopUpProducts",
  USER_ATU_PRODUCTS = "/account/autoTopUps",
}

export enum StorageKeys {
  USER_DATA_KEY = "userData",
  TOKEN_KEY = "token",
  USER_EMAIL_IDENTIFIER = "userEmailIdentifier",
  SLUG_KEY = "slug",
  OFFER_KEY = "offer",
  CURRENT_STEP = "current_step",
  PROFILE = "profile",
  REFRESH_TOKEN = "refreshToken",
}

export const LOCATION_DEFAULT = "default";
export const DEFAULT_SECRET_KEY = "default key";
export const REFERRAL_ID = "id";
export const LIVESTREAM_LOCATION_ID = "48717";

export enum MixpanelEvents {
  // referral screen
  REFERRAL_LANDING_PAGE_VIEWED = "referrals_landing_page_viewed",
  REFERRAL_EMAIL_SUBMIT_TAPPED = "purchase_referral_email_address_submit_tapped",

  // step 1
  OFFER_USED = "purchase_offer_used",
  EMAIL_PAGE_VIEWED = "purchase_email_address_page_viewed",
  STEP1_EMAIL_SUBMIT_TAPPED = "purchase_step1_email_address_submit_tapped",
  EMAIL_SUBMIT_RESPONSE_RECEIVED = "purchase_email_submit_response_received",

  // not eligible for trial
  ACCOUNT_EXIST_PAGE_VIEWED = "purchase_account_exists_page-viewed",
  ACCOUNT_EXIST_BUTTON_TAPPED = "purchase_account_exists_button-tapped",

  // login screen
  USER_LOGGED_IN = "purchase_user_logged_in",
  ACCOUNT_EXIST_LOGIN_PAGE_VIEWED = "purchase_account_exists_login_page-viewed",
  ACCOUNT_LOGIN_PAGE_VIEWED = "purchase_account_login_page-viewed",
  ACCOUNT_EXIST_LOGIN_BUTTON_TAPPED = "purchase_account_exists_login_button-tapped",

  // product selection screen
  PRODUCT_LIST_PAGE_VIEWED = "purchase_product_list_page_viewed",
  STUDIO_CHANGED = "purchase_studio_changed",
  SELECTED_PRODUCT_CHANGED = "purchase_selected_product_changed",
  PRODUCT_LIST_CHECKOUT_TAPPED = "purchase_product_list_checkout_tapped",
  PRODUCT_LIST_AUTO_APPLIED_DISCOUNT_CHECKED = "purchase_discount_code_checked",
  PRODUCT_LIST_AUTO_APPLIED_DISCOUNT_CHECK_FAILED = "purchase_discount_code_check_failed",

  // customer details
  CUSTOMER_DETAILS_PAGE_VIEWED = "purchase_customer_details_page_viewed",
  CUSTOMER_DETAILS_BUTTON_TAPPED = "purchase_customer_details_page_button_tapped",
  CUSTOMER_DETAILS_CREATION_FAILED = "purchase_customer_details_creation_failed",

  // add payment method
  PAYMENT_METHOD_PAGE_VIEWED = "purchase_payment_method_page_viewed",
  PAYMENT_METHOD_SUBMIT_TAPPED = "purchase_payment_method_submit_tapped",
  NEW_PAYMENT_METHOD_SUBMIT_FAILED = "purchase_new_payment_method_submit_failed",

  // checkout
  CHECKOUT_PAGE_VIEWED = "purchase_checkout_page_viewed",
  CHECKOUT_FAILED_PAGE_VIEWED = "purchase_checkout_failed_page_viewed",
  CHECKOUT_PROCESSING_PAGE_VIEWED = "purchase_checkout_processing_page_viewed",
  CHECKOUT_FAILED = "purchase_checkout_failed",
  CHECKOUT_SUBMIT_BUTTON_TAPPED = "purchase_checkout_submit_button_tapped",
  CHECKOUT_AUTO_APPLIED_DISCOUNT_CHECKED = "purchase_checkout_auto_applied_discount_checked",
  CHECKOUT_AUTO_APPLIED_DISCOUNT_CHECK_FAILED = "purchase_checkout_auto_applied_discount_check_failed",

  // discount block
  DISCOUNT_CODE_SUBMIT_TAPPED = "purchase_offer_discount_code_submit_request",
  DISCOUNT_CODE_SUBMIT_FAILED = "purchase_offer_discount_code_submit_failed",
  DISCOUNT_CODE_SUBMIT_SUCCEEDED = "purchase_offer_discount_code_submit_succeeded",

  // balance block
  ACCOUNT_BALANCE_SWITCHER_CHANGED = "purchase_account_balance_switcher_changed",

  // gift card block
  TOP_UP_WITH_GIFT_CARD_TAPPED = "purchase_top_up_with_gift_card_tapped",
  GIFT_CARD_SUBMIT_TAPPED = "purchase_gift_card_submit_tapped",
  GIFT_CARD_SUBMIT_SUCCEEDED = "purchase_gift_card_submit_succeeded",
  GIFT_CARD_SUBMIT_FAILED = "purchase_gift_card_submit_failed",

  // step 5 (success screen)
  CHECKOUT_SUCCESS_PAGE_VIEWED = "purchase_checkout_success_page_viewed",
  GOOGLE_LINK_TAPPED = "purchase_google_link_tapped",
  APPLE_LINK_TAPPED = "purchase_apple_link_tapped",
  DIGITAL_MEMBERSHIP_CTA_TAPPED = "purchase_digital_memberships_cta_tapped",

  INDIVIDUAL_ATU_DISCOUNT_APPLIED = "individual_atu_discount_applied",
  INDIVIDUAL_CODE_NOT_ELIGIBLE_FOR_ATU = "individual_code_not_eligible_for_atu",
  INDIVIDUAL_CODE_MISUSE = "individual_code_misuse",
}

export enum EnvironmentType {
  development = "dev",
  production = "prod",
  test = "test",
}

export enum STEPS {
  step2 = "step2",
  step3 = "step3",
  step4 = "step4",
  step5 = "step5",
}

export const GOOGLE_STORE_LINK =
  "https://play.google.com/store/apps/details?id=com.zingfit.BLOK_london";
export const APPLE_STORE_LINK =
  "https://apps.apple.com/us/app/blok-workouts-fitness/id1445073880";
export const BLOK_URL = "https://www.bloklondon.com/";
export const BLOK_STUDIOS_URL = "https://www.bloklondon.com/studios";
export const IAP_TERMS_AND_CONDITIONS_LINK =
  "https://bloklondon.zendesk.com/hc/en-gb/categories/7087744508573-TERMS-AND-CONDITIONS";
export const CLICK_APP = "https://click.bloklondon.com/";

export enum CurrencyKeys {
  GBP = "GBP",
  USD = "USD",
  EUR = "EUR",
}

export const CURRENCIES = {
  [CurrencyKeys.GBP]: "£",
  [CurrencyKeys.USD]: "$",
  [CurrencyKeys.EUR]: "€",
};

export enum FeatureFlags {
  ATU = "atu_feature_flag",
}
