import { StorageKeys } from "../constants";

const EXP_DURATION = 1000 * 60 * 60 * (24 * 30); // 30 days

const isObject = (jsonString: string) =>
  jsonString.includes("{", 0) &&
  jsonString.includes("}", jsonString.length - 1);

export function setWithExpiry(key: StorageKeys, value: any) {
  const now = new Date();

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value,
    expiry: now.getTime() + EXP_DURATION,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getWithExpiry(key: StorageKeys) {
  const itemStr = localStorage.getItem(key);
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }
  const item = isObject(itemStr) ? JSON.parse(itemStr) : itemStr;
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (!item?.expiry || now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }
  return typeof item === "string" ? item : item?.value;
}

export function removeStorageItem(key: StorageKeys) {
  localStorage.removeItem(key);
}

export function setToSessionStorage(key: StorageKeys, value: any) {
  const data = { value };
  sessionStorage.setItem(key, JSON.stringify(data));
}

export function getFromSessionStorage(key: StorageKeys) {
  const itemStr = sessionStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = isObject(itemStr) ? JSON.parse(itemStr) : itemStr;

  return typeof item === "string" ? item : item.value;
}

export function removeFromSessionStorage(key: StorageKeys) {
  sessionStorage.removeItem(key);
}
