import axiosInstance from "./index";
import { StepEndpoints, StorageKeys } from "../constants";
import {
  AddCardRequest,
  ATUProduct,
  AutoAppliedDiscountParams,
  CheckoutParams,
  CheckoutStatusResponse,
  DiscountCodeParams,
  DiscountCodeResponse,
  GetATUResponse,
  GetUserCardListResponse,
  GetUserMembershipsResponse,
  GiftCardParams,
  LoginParams,
  LoginResponse,
  OfferInfo,
  Profile,
  SelectProductRequest,
  Step1Response,
  SubmitStep2,
  UserATUProduct,
  UserData,
} from "../types";
import { setWithExpiry } from "./storage";

export const getOfferInfo = (slug: string = "default") =>
  axiosInstance.get<null, OfferInfo>(`${StepEndpoints.OFFER}/${slug}`);

export const getCheckoutStatus = (username: string) =>
  axiosInstance.get<null, CheckoutStatusResponse>(
    `${StepEndpoints.CHECKOUT_STATUS}/${username}`
  );

export const postStep1 = (data: {
  username: string;
  source: string;
  trainingAdvice: boolean;
  slug?: string;
  referredBy?: string;
}) =>
  axiosInstance.post<
    {
      username: string;
      source: string;
      slug: string;
      trainingAdvice: boolean;
      referredBy?: string;
    },
    Step1Response
  >(StepEndpoints.STEP1, data);

export const postStepProductSelect = (resp: SelectProductRequest) =>
  axiosInstance.post<SelectProductRequest, UserData>(
    StepEndpoints.STEP1_SELECT_PRODUCT,
    resp
  );

export const postCreateAccount = (resp: SubmitStep2) =>
  axiosInstance.post<SubmitStep2, UserData>(StepEndpoints.STEP2, resp);

export interface PostStep3Request {
  username: string;
  cardHolderName: string;
  number: string;
  ccv: string;
  expiration_month: string;
  expiration_year: string;
  zip: string;
}
export const postStep3 = (data: PostStep3Request) =>
  axiosInstance.post(StepEndpoints.STEP3, data);

export interface CheckoutExistingCard {
  card_id: string;
  username: string;
}

export const postStep3Existing = (data: CheckoutExistingCard) =>
  axiosInstance.post(StepEndpoints.STEP3_EXISTING, data);

export type Site = {
  id: string;
  siteNumber: number;
  name: string;
  city: string;
  zip: string;
  phone: string;
  address: string;
  address2: string;
  timeZone: string;
  displayForPreferredLocation: boolean;
  private: boolean;
};

export const getSites = (): Promise<Site[]> =>
  axiosInstance.get(StepEndpoints.SITES);

export const getProfile = () =>
  axiosInstance.get<null, Profile>(StepEndpoints.PROFILE);

export const getUserCardListRequest = async (username: string) =>
  axiosInstance.get<{ username: string }, GetUserCardListResponse>(
    StepEndpoints.CARDS,
    { params: { username } }
  );

export const login = async (username?: string, password?: string) =>
  axiosInstance
    .post<LoginParams, LoginResponse>(StepEndpoints.LOGIN, {
      username,
      password,
    })
    .then((resp) => {
      if (resp.access_token) {
        setWithExpiry(StorageKeys.TOKEN_KEY, resp.access_token);
        setWithExpiry(StorageKeys.REFRESH_TOKEN, resp.refresh_token);
        axiosInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${resp.access_token}`;
      }
      return resp;
    });

export const addCard = async (data: AddCardRequest) =>
  axiosInstance.post<AddCardRequest, string>(StepEndpoints.ADD_CARD, data);

export const checkout = async (data: CheckoutParams) =>
  axiosInstance.post<CheckoutParams, string>(StepEndpoints.CHECKOUT, data);

export const getMemberships = async () =>
  axiosInstance.get<undefined, GetUserMembershipsResponse>(
    StepEndpoints.GET_MEMBERSHIPS
  );

export const checkDiscountCode = async ({
  code,
  ...rest
}: DiscountCodeParams) =>
  axiosInstance.post<DiscountCodeParams, DiscountCodeResponse>(
    `${StepEndpoints.CHECK_DISCOUNT_CODE}/${code}`,
    rest
  );

export const redeemGiftCard = async (params: GiftCardParams) =>
  axiosInstance.post<GiftCardParams, string>(
    StepEndpoints.REDEEM_GIFT_CARD,
    params
  );

export const checkAutoAppliedDiscount = async (
  params: AutoAppliedDiscountParams
) =>
  axiosInstance.post<AutoAppliedDiscountParams, DiscountCodeResponse>(
    StepEndpoints.AUTO_APPLIED_DISCOUNT,
    params
  );

export const getAtuProducts = async () =>
  axiosInstance.get<undefined, GetATUResponse<ATUProduct>>(
    StepEndpoints.ATU_PRODUCTS
  );

export const getUserAtuProducts = async () =>
  axiosInstance.get<undefined, GetATUResponse<UserATUProduct>>(
    StepEndpoints.USER_ATU_PRODUCTS
  );
