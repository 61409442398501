import React, { Suspense } from "react";
import Context from "./Context";
import { Outlet } from "react-router-dom";
import Loader from "./components/shared/Loader";
import { CheckoutContextProvider } from "./CheckoutContext";

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <Context>
        <CheckoutContextProvider>
          <Outlet />
        </CheckoutContextProvider>
      </Context>
    </Suspense>
  );
}

export default App;
