import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Alert, Snackbar, useMediaQuery, useTheme } from "@mui/material";
import {
  EnvironmentType,
  MixpanelEvents,
  snackbarDesktop,
  snackbarMobile,
  STEPS,
  StorageKeys,
} from "./constants";
import { Discount, OfferInfo, Product, Profile, UserData } from "./types";
import "./App.css";
import { getOfferInfo, getProfile } from "./services/requests";
import mixpanel from "mixpanel-browser";
import {
  getWithExpiry,
  removeStorageItem,
  setWithExpiry,
} from "./services/storage";
import { Routes } from "./routes";
import { refreshAccessToken } from "./Utils";
import { usePostHog } from "posthog-js/react";

export interface IAppContext {
  setError: (error: string) => void;
  isDesktop: boolean;
  offerInfo: OfferInfo;
  offerLoading: boolean;
  callEvent: (event: MixpanelEvents, data?: any) => void;
  // identifyUser: (email: string) => void;
  updateProfile: () => void;
  flowProgress: number;
  showProgress: boolean;
  setShowProgress: (value: boolean) => void;
  loading: boolean;
  error: string;
  userData: UserData | null;
  alreadyExistUser: boolean;
  existedUserTrialLogin: boolean;
  setLoading: (value: boolean) => void;
  setAlreadyExistUser: (value: boolean) => void;
  setExistedUserTrialLogin: (value: boolean) => void;
  setUserData: (value: UserData) => void;
  isLogin: boolean;
  setIsLogin: (value: boolean) => void;
  changeSelectedProduct: (product: Product) => void;
  selectedProduct: Product | null;
  profile: Profile | null;
  requestProfile: () => void;
  slugChecked: boolean;
  defaultDiscount: Discount[];
  identifyUser: (email: string) => void;
  logout: () => void;
}
type AppContextType = IAppContext | {};

const AppContext = createContext<AppContextType>({});

export const useAppContext = (): AppContextType => useContext(AppContext);

function Context({ children }: { children: ReactNode }) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const postHog = usePostHog();

  const [error, setError] = useState("");
  const [tokenRefreshed, setTokenRefreshed] = useState(false);
  const [offerInfo, setOfferInfo] = useState<OfferInfo | null>(null);
  const [offerLoading, setOfferLoading] = useState<boolean>(false);
  const [mixpanelInitialized, setMixpanelInitialized] = useState(false);
  const [flowProgress, setFlowProgress] = useState(1);
  const [showProgress, setShowProgress] = useState(true);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState<UserData | null>(null);
  const [alreadyExistUser, setAlreadyExistUser] = useState(false);
  const [existedUserTrialLogin, setExistedUserTrialLogin] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [profile, setProfile] = useState<Profile | null>(null);
  const [slugChecked, setSlugChecked] = useState(false);

  const logout = useCallback(() => {
    try {
      setProfile(null);
      setUserData(null);
      setSelectedProduct(offerInfo?.selectedProduct || null);
      setAlreadyExistUser(false);

      removeStorageItem(StorageKeys.TOKEN_KEY);
      removeStorageItem(StorageKeys.USER_DATA_KEY);
      removeStorageItem(StorageKeys.PROFILE);
      removeStorageItem(StorageKeys.CURRENT_STEP);
      mixpanel?.reset();
      postHog?.reset();

      setIsLogin(true);
      setExistedUserTrialLogin(true);
      navigate(`/${Routes.STEP1}`, { replace: true });
    } catch (e) {
      console.log(e);
    }
  }, [navigate, offerInfo?.selectedProduct, postHog]);

  useEffect(() => {
    if (location.pathname.includes(Routes.STEP2)) {
      setFlowProgress(2);
    } else if (location.pathname.includes(Routes.STEP3)) {
      setFlowProgress(3);
    } else if (location.pathname.includes(Routes.STEP4)) {
      setFlowProgress(4);
    } else {
      setFlowProgress(1);
    }
    if (
      !location.pathname.includes(Routes.STEP2) &&
      !location.pathname.includes(Routes.STEP3) &&
      !location.pathname.includes(Routes.STEP4) &&
      !location.pathname.includes(Routes.STEP5) &&
      !location.pathname.includes(Routes.NOT_FOUND) &&
      !location.pathname.includes("referral")
    ) {
      const search = new URLSearchParams(location.search);
      const previousSlug = getWithExpiry(StorageKeys.SLUG_KEY);
      let { slug } = params;
      if (
        !slug ||
        slug === Routes.STEP1 ||
        slug === "getonboard" ||
        slug === "buy"
      ) {
        slug = previousSlug;
      }
      if (search.has(StorageKeys.OFFER_KEY)) {
        slug = search.get(StorageKeys.OFFER_KEY) || slug;
      }
      if (slug) {
        setWithExpiry(StorageKeys.SLUG_KEY, slug);
      }
      if (previousSlug !== slug && slug !== "default") {
        removeStorageItem(StorageKeys.CURRENT_STEP);
        const _profile = getWithExpiry(StorageKeys.PROFILE);
        if (_profile) {
          navigate(`/${Routes.STEP2}`);
        } else {
          navigate(`/${Routes.STEP1}`);
        }
      }
    }
  }, [params, location, navigate]);

  useEffect(() => {
    const currentStep = getWithExpiry(StorageKeys.CURRENT_STEP);

    const pathname = location.pathname.replace("/", "");
    if (pathname !== "referral") {
      if (currentStep && currentStep !== pathname) {
        const search = location.search;
        navigate(`/${currentStep}${search}`);
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    postHog.debug(process.env.NODE_ENV === "development");
  }, [postHog]);

  useEffect(() => {
    const pathname = location.pathname.replace("/", "");
    if (STEPS[pathname as STEPS]) {
      setWithExpiry(StorageKeys.CURRENT_STEP, pathname);
    }
  }, [location.pathname]);

  useEffect(() => {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN || "", {
      debug: !process.env.NODE_ENV || process.env.NODE_ENV === "development",
    });
    setMixpanelInitialized(true);
  }, []);

  const callEvent = useCallback(
    (event: MixpanelEvents, data: any) => {
      if (mixpanelInitialized) {
        const generatedEvent = `fe-${
          EnvironmentType[process.env.NODE_ENV]
        }-${event}`;

        mixpanel.track(generatedEvent, data);
      }
    },
    [mixpanelInitialized]
  );

  const changeSelectedProduct = useCallback((product: Product) => {
    setSelectedProduct(product);
  }, []);

  const changeUserData = useCallback((data: UserData | null) => {
    setUserData(data);
  }, []);

  const getCurrentOffer = useCallback(async () => {
    try {
      setOfferLoading(true);
      const search = new URLSearchParams(location.search);
      let slug = params?.slug === "buy" ? "" : params?.slug;
      // console.log(location.pathname);
      if (
        location.pathname.includes("referral") &&
        !!process.env.REACT_APP_SIGNUP_URL
      ) {
        window.location.replace(
          `${process.env.REACT_APP_SIGNUP_URL}/referral?${location.search}`
        );
        return;
      }
      if (search.has(StorageKeys.OFFER_KEY)) {
        slug = search.get(StorageKeys.OFFER_KEY) || "";
      }
      if (!slug) {
        slug = getWithExpiry(StorageKeys.SLUG_KEY);
      }
      // console.log("slug", slug, getWithExpiry(StorageKeys.SLUG_KEY));
      if (slug) {
        callEvent(MixpanelEvents.OFFER_USED, { offer: slug });
        const resp = await getOfferInfo(slug);
        setOfferInfo(resp);
        if (resp?.selectedProduct?.id) {
          changeSelectedProduct(resp.selectedProduct);
          if (resp?.code) {
            setExistedUserTrialLogin(true);
            setIsLogin(true);
          }
        } else {
          navigate("/not_found", { replace: true });
        }
      } else {
        window.location.replace(process.env.REACT_APP_BUY_PAGE || "");
      }
    } catch (e: any) {
      setError(
        e?.data?.message ||
          e?.data?.error ||
          "Unexpected issue. Cannot show current offer."
      );
    } finally {
      setSlugChecked(true);
      setOfferLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  const identifyUser = useCallback((email: string) => {
    mixpanel.identify(email);
  }, []);

  const requestProfile = useCallback(async () => {
    try {
      const resp = await getProfile();
      if (resp) {
        setProfile(resp);
        setUserData((prev) => ({ ...prev, ...resp }));
        const prevUserData = getWithExpiry(StorageKeys.USER_DATA_KEY);
        setWithExpiry(StorageKeys.PROFILE, resp);
        setWithExpiry(StorageKeys.USER_DATA_KEY, {
          ...prevUserData,
          ...resp,
          username: resp.email,
        });
        identifyUser(resp.email);
        const {
          email,
          id,
          birth_date,
          city,
          country,
          first_name,
          phone_number,
          last_name,
          home_location,
        } = resp;
        postHog?.identify(resp?.email, {
          first_name,
          last_name,
          email,
          id,
          birth_date,
          city,
          country,
          phone_number,
          home_location,
        });
        mixpanel.people.set({ ...resp, offer: offerInfo?.slug });
      }
    } catch (error) {
      console.log(error);
    }
  }, [identifyUser, postHog, offerInfo?.slug]);

  const _refreshAccessToken = useCallback(refreshAccessToken, []);

  useEffect(() => {
    if (!!getWithExpiry(StorageKeys.TOKEN_KEY)) {
      requestProfile();
    }
  }, [requestProfile]);

  useEffect(() => {
    if (
      !!getWithExpiry(StorageKeys.REFRESH_TOKEN) &&
      !!getWithExpiry(StorageKeys.TOKEN_KEY) &&
      !tokenRefreshed
    ) {
      _refreshAccessToken();
      setTokenRefreshed(true);
    }
  }, [_refreshAccessToken, tokenRefreshed]);

  useEffect(() => {
    getCurrentOffer();
  }, [getCurrentOffer]);

  return (
    <AppContext.Provider
      value={{
        setError,
        isDesktop,
        offerInfo,
        offerLoading,
        callEvent,
        flowProgress,
        showProgress,
        setShowProgress,
        loading,
        setLoading,
        userData,
        setUserData: changeUserData,
        existedUserTrialLogin,
        setExistedUserTrialLogin,
        alreadyExistUser,
        setAlreadyExistUser,
        isLogin,
        setIsLogin,
        changeSelectedProduct,
        selectedProduct,
        profile,
        requestProfile,
        slugChecked,
        logout,
        identifyUser,
      }}
    >
      {children}
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError("")}
        anchorOrigin={isDesktop ? snackbarDesktop : snackbarMobile}
      >
        <Alert
          onClose={() => setError("")}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>
    </AppContext.Provider>
  );
}

export default Context;
