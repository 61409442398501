import React from "react";
import { Outlet } from "react-router-dom";
import { Grid } from "@mui/material";
import "./App.css";
import { IAppContext, useAppContext } from "./Context";
import Loader from "./components/shared/Loader";

function Steps() {
  const context = useAppContext() as IAppContext;
  return (
    <Grid
      container
      sx={{ height: "100%", width: "100%", justifyContent: "center" }}
    >
      <Grid
        item
        sx={{
          height: { md: "100%", xs: "auto" },
          maxWidth: { sm: "100%", xs: "100%" },
          width: "100%",
          px: { md: 0, xs: 3 },
          position: "relative",
        }}
      >
        {context?.slugChecked ? <Outlet /> : <Loader />}
      </Grid>
    </Grid>
  );
}

export default Steps;
