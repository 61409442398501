import { Discount } from "./types";
import { getWithExpiry, setWithExpiry } from "./services/storage";
import { StorageKeys } from "./constants";

export const parseError = (error: any): string | undefined => {
  if (typeof error === "string") {
    return error;
  }
  if (!Object.keys(error).length) {
    return "";
  }
  if (typeof error === "object") {
    return parseError(error?.[Object.keys(error)?.[0]]);
  }
};

export const getDiscountAmount = (discount: Discount, productPrice: number) => {
  let _discountAmount = 0;
  const discountValue = +discount.attributes.benefit_value;
  if (discount.attributes.benefit_type === "Absolute") {
    _discountAmount = !isNaN(discountValue) ? discountValue : 0;
  } else {
    if (!isNaN(discountValue)) {
      _discountAmount = productPrice * (discountValue / 100);
    }
  }
  return _discountAmount;
};

export const refreshAccessToken = async (
  successCallback?: () => void,
  failCallback?: () => void
) => {
  try {
    const version = "web checkout";
    const token = getWithExpiry(StorageKeys.TOKEN_KEY);
    const refreshToken = getWithExpiry(StorageKeys.REFRESH_TOKEN);
    console.warn("REFRESH TOKEN REQUEST");
    const resp = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/account/refreshToken?refresh_token=${refreshToken}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          appVersion: version,
          ...(token && { Authorization: `Bearer ${token}` }),
        },
      }
    );
    const newAccessToken = await resp.json();
    setWithExpiry(StorageKeys.TOKEN_KEY, newAccessToken.access_token);
    setWithExpiry(StorageKeys.REFRESH_TOKEN, newAccessToken.refresh_token);
    successCallback?.();
  } catch (err) {
    console.error("get refresh token failed: ", err);
    failCallback?.();
    return null;
  }
};
