import {
  ATUProduct,
  AutoAppliedDiscountParams,
  Discount,
  DiscountCodeParams,
  DiscountCodeResponse,
  Membership,
  UserATUProduct,
} from "./types";
import { FeatureFlags, MixpanelEvents } from "./constants";
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { IAppContext, useAppContext } from "./Context";
import {
  checkAutoAppliedDiscount,
  checkDiscountCode,
  getAtuProducts,
  getMemberships,
  getUserAtuProducts,
} from "./services/requests";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { useFeatureFlagEnabled } from "posthog-js/react";

dayjs.extend(isBetween);

export interface CheckoutContextType {
  defaultDiscount: Discount[];
  setAutoAppliedDiscount: (value: Discount[]) => void;
  requestAutoAppliedDiscount: (products: string[]) => void;
  autoAppliedDiscount: Discount[];
  discount: Discount[];
  atuDiscount?: Discount;
  individualDiscount?: Discount;
  isSelectedProductCanBeATU: boolean;
  autoTopUp: boolean;
  changeAutoTopUp: (value: boolean) => void;
  submitDiscountCode: (
    discountCode: string,
    autoApplied?: boolean
  ) => Promise<null | DiscountCodeResponse>;
  changeDiscount: (value: Discount[]) => void;
  changeATUDiscount: (value: Discount | undefined) => void;
  changeIndividualDiscount: (value: Discount | undefined) => void;
  changeCodeError: (value: string) => void;
  discountLoading: boolean;
  changeDiscountLoading: (value: boolean) => void;
  code: string;
  changeCode: (value: string) => void;
  codeError: string;
  isATUEnabledForSelectedProduct: boolean;
}

const defaultState = {
  defaultDiscount: [],
  setAutoAppliedDiscount: () => {},
  requestAutoAppliedDiscount: () => {},
  autoAppliedDiscount: [],
  discount: [],
  atuDiscount: undefined,
  individualDiscount: undefined,
  isSelectedProductCanBeATU: false,
  autoTopUp: false,
  changeAutoTopUp: () => {},
  submitDiscountCode: () => Promise.resolve(null),
  changeDiscount: () => {},
  changeATUDiscount: () => {},
  changeIndividualDiscount: () => {},
  changeCodeError: () => {},
  discountLoading: false,
  changeDiscountLoading: () => {},
  code: "",
  changeCode: () => {},
  codeError: "",
  isATUEnabledForSelectedProduct: false,
};

const CheckoutContext = createContext<CheckoutContextType>(defaultState);

export const useCheckoutContext = (): CheckoutContextType =>
  useContext(CheckoutContext);

export const CheckoutContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { profile, callEvent, selectedProduct, offerInfo } =
    useAppContext() as IAppContext;
  const atuFeatureEnabled = useFeatureFlagEnabled(FeatureFlags.ATU);
  const [defaultDiscount, setDefaultDiscount] = useState<Discount[]>([]);

  const [memberships, setMemberships] = useState<Membership[]>([]);
  const [, setMembershipsLoading] = useState(false);
  const [membershipsFetched, setMembershipsFetched] = useState(false);

  const [code, setCode] = useState("");
  const [atuProducts, setAtuProducts] = useState<ATUProduct[]>([]);
  const [userATUProducts, setUserATUDiscounts] = useState<UserATUProduct[]>([]);
  const [autoTopUp, setAutoTopUp] = useState(false);
  const [autoAppliedDiscount, setAutoAppliedDiscount] = useState<Discount[]>(
    []
  );
  const [discountLoading, setDiscountLoading] = useState(false);
  const [discount, setDiscount] = useState<Discount[]>([]);
  const [atuDiscount, setATUDiscount] = useState<Discount | undefined>();
  const [individualDiscount, setIndividualDiscount] = useState<
    Discount | undefined
  >(undefined);

  const [codeError, setCodeError] = useState<string>("");

  const isAuthorisedUser = useMemo(() => !!profile, [profile]);

  useEffect(() => {
    if (isAuthorisedUser) {
      setMembershipsLoading(true);
      getMemberships()
        .then((resp) => {
          setMemberships(resp.results);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setMembershipsLoading(false);
          setMembershipsFetched(true);
        });
    }
  }, [isAuthorisedUser]);

  const filteredMemberships = useMemo(
    () =>
      (memberships || []).filter(
        (item) =>
          item.is_active &&
          !(
            !!item.freeze_start_date &&
            !!item.freeze_end_date &&
            dayjs().isBetween(
              dayjs(item.freeze_start_date),
              dayjs(item.freeze_end_date),
              "date",
              "[]"
            )
          )
      ),
    [memberships]
  );

  const requestAutoAppliedDiscount = useCallback(
    (products: string[], unauthorized?: boolean) => {
      if (unauthorized || (membershipsFetched && filteredMemberships)) {
        const params: AutoAppliedDiscountParams = {
          productIds: products.map((item) => `${item}`),
          membershipIds: filteredMemberships.map((item) => item?.id),
        };
        checkAutoAppliedDiscount(params)
          .then((resp) => {
            callEvent(
              MixpanelEvents.PRODUCT_LIST_AUTO_APPLIED_DISCOUNT_CHECKED,
              {
                params,
                response: resp,
              }
            );
            if (resp.data?.length) {
              setAutoAppliedDiscount(resp.data);
            }
          })
          .catch((e) => {
            callEvent(
              MixpanelEvents.PRODUCT_LIST_AUTO_APPLIED_DISCOUNT_CHECK_FAILED,
              e
            );
            console.log("AUTO-APPLIED DISCOUNT ERROR", e);
          });
      }
    },
    [callEvent, filteredMemberships, membershipsFetched]
  );

  useEffect(() => {
    if (offerInfo?.selectedProduct) {
      const products = Array.from(
        new Set([
          offerInfo?.selectedProduct?.id,
          ...offerInfo?.products?.map((item) => item.id),
        ])
      );
      requestAutoAppliedDiscount(products, true);
    }
  }, [
    offerInfo?.products,
    offerInfo?.selectedProduct,
    requestAutoAppliedDiscount,
  ]);

  const requestAtuData = useCallback(async () => {
    try {
      if (atuFeatureEnabled) {
        const resp = await getAtuProducts();
        setAtuProducts(resp.content);
        if (profile) {
          const userATUProducts = await getUserAtuProducts();
          setUserATUDiscounts(userATUProducts.content);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [profile, atuFeatureEnabled]);

  const changeAutoAppliedDiscount = useCallback((value: Discount[]) => {
    setAutoAppliedDiscount(value);
  }, []);

  const changeCodeError = useCallback((value: string) => {
    setCodeError(value);
  }, []);

  const changeAutoTopUp = useCallback((value: boolean) => {
    setAutoTopUp(value);
    setIndividualDiscount(undefined);
    setCode("");
    setDiscount([]);
  }, []);

  const changeDiscount = useCallback((value: Discount[]) => {
    setDiscount(value);
  }, []);

  const changeATUDiscount = useCallback((value: Discount | undefined) => {
    setATUDiscount(value);
  }, []);

  const changeIndividualDiscount = useCallback(
    (value: Discount | undefined) => {
      setIndividualDiscount(value);
    },
    []
  );

  const changeDiscountLoading = useCallback((value: boolean) => {
    setDiscountLoading(value);
  }, []);

  const changeCode = useCallback((value: string) => {
    setCode(value);
  }, []);

  useEffect(() => {
    requestAtuData();
  }, [requestAtuData]);

  // function for applying default (from offer) discount code
  const requestDiscountCode = useCallback(
    (discountCode: string) => {
      if (selectedProduct) {
        const data = {
          code: discountCode.toUpperCase(),
          membershipIds: [],
          productIds: [selectedProduct.id],
        };
        callEvent(MixpanelEvents.DISCOUNT_CODE_SUBMIT_TAPPED, data);
        checkDiscountCode(data)
          .then((resp) => {
            setDefaultDiscount(resp.data);
            callEvent(MixpanelEvents.DISCOUNT_CODE_SUBMIT_SUCCEEDED, {
              discount: resp.data,
            });
          })
          .catch((error) => {
            let errorMessage = "Invalid discount code";
            // setInputState(InputState.FAILED);
            if (error?.error === "Individual discount code misuse.") {
              errorMessage =
                "This code can only be used by the account linked to the email address it was sent to.";
              callEvent(MixpanelEvents.INDIVIDUAL_CODE_MISUSE, error);
            } else {
              callEvent(MixpanelEvents.DISCOUNT_CODE_SUBMIT_FAILED, error);
            }

            changeCodeError(errorMessage);
            console.log(error?.error, errorMessage);
          });
      }
    },
    [callEvent, changeCodeError, selectedProduct]
  );

  useEffect(() => {
    if (offerInfo?.code) {
      requestDiscountCode(offerInfo.code);
    }
  }, [offerInfo?.code, requestDiscountCode]);

  const atuCode = useMemo(() => {
    let _code = "";
    if (selectedProduct && atuProducts?.length) {
      const product = atuProducts.find(
        (item) => +item.product_id === +selectedProduct.id
      );

      // console.log('product', product);
      if (product) {
        if (
          autoAppliedDiscount &&
          autoAppliedDiscount?.findIndex((item) =>
            item.attributes.name.toLowerCase().includes("unlimited")
          ) > -1
        ) {
          _code = product.unlimited_discount_code;
        } else if (autoAppliedDiscount?.length) {
          _code = product.member_discount_code;
        } else {
          _code = product.normal_discount_code;
        }
      }
    }
    return _code;
  }, [autoAppliedDiscount, atuProducts, selectedProduct]);

  // function for applying any other type of discount code (autoApplied, individual, etc.)
  const submitDiscountCode = useCallback(
    async (_code: string, isAutoApplied: boolean = false) => {
      try {
        if (selectedProduct && _code) {
          changeDiscountLoading(true);
          // inputRef?.current?.blur();
          // setInputState(InputState.PROCESSING);

          changeCodeError("");
          const discountCodeRequest: DiscountCodeParams = {
            atu_active: autoTopUp,
            code: _code,
            membershipIds: filteredMemberships.map((item) => item?.id),
            productIds: [selectedProduct.id],
          };
          const resp = await checkDiscountCode(discountCodeRequest);

          if (resp.data?.length) {
            callEvent(
              MixpanelEvents.PRODUCT_LIST_AUTO_APPLIED_DISCOUNT_CHECKED,
              {
                id: resp?.data?.[0].id,
                name: resp?.data?.[0].attributes.name,
                value: resp?.data?.[0].attributes.benefit_value,
              }
            );
            // console.log('promo code: ', resp.data?.[0]);
            // setInputState(InputState.SUCCESS);
            const _discount = resp.data?.[0];
            if (isAutoApplied) {
              setATUDiscount(_discount);
            } else {
              if (autoTopUp) {
                if (
                  _discount?.attributes.for_atu &&
                  _discount?.attributes?.individual
                ) {
                  // setIndividualDiscount(_discount);
                  callEvent(MixpanelEvents.INDIVIDUAL_ATU_DISCOUNT_APPLIED, {
                    id: _discount?.id,
                    name: _discount?.attributes.name,
                    value: _discount?.attributes.benefit_value,
                    code: _discount?.attributes.codes?.[0],
                  });
                  setIndividualDiscount(_discount);
                  setDiscount([]);
                } else {
                  changeCodeError(
                    "This discount can’t be combined with Auto Top-up"
                  );
                  callEvent(
                    MixpanelEvents.INDIVIDUAL_CODE_NOT_ELIGIBLE_FOR_ATU,
                    {
                      id: _discount?.id,
                      name: _discount?.attributes.name,
                      value: _discount?.attributes.benefit_value,
                      code: _discount?.attributes.codes?.[0],
                    }
                  );
                }
              } else {
                setDiscount(resp.data);
              }
            }
          } else {
            // setInputState(InputState.FAILED);
            changeCodeError("Invalid discount code");
          }
          return resp;
        }
        return null;
      } catch (discountCodeError: any) {
        setDiscount([]);
        setIndividualDiscount(undefined);
        let errorMessage = "Invalid discount code";
        // setInputState(InputState.FAILED);
        if (discountCodeError?.error === "Individual discount code misuse.") {
          errorMessage =
            "This code can only be used by the account linked to the email address it was sent to.";
          callEvent(MixpanelEvents.INDIVIDUAL_CODE_MISUSE, discountCodeError);
        } else {
          callEvent(
            MixpanelEvents.PRODUCT_LIST_AUTO_APPLIED_DISCOUNT_CHECK_FAILED,
            discountCodeError
          );
        }
        changeCodeError(errorMessage);
        return null;
      } finally {
        changeDiscountLoading(false);
      }
    },
    [
      autoTopUp,
      callEvent,
      changeCodeError,
      changeDiscountLoading,
      filteredMemberships,
      selectedProduct,
    ]
  );

  useEffect(() => {
    if (atuCode) {
      submitDiscountCode(atuCode, true);
    }
  }, [atuCode, submitDiscountCode]);

  const isSelectedProductCanBeATU = useMemo(() => {
    if (selectedProduct && atuProducts?.length) {
      const product = atuProducts.find((item) => {
        return +item.product_id === +selectedProduct.id;
      });
      return !!product;
    }
    return false;
  }, [atuProducts, selectedProduct]);

  const isATUEnabledForSelectedProduct = useMemo(() => {
    if (selectedProduct && userATUProducts?.length) {
      return !!userATUProducts.find(
        (item) => +item.product_id === +selectedProduct.id
      );
    }
    return false;
  }, [selectedProduct, userATUProducts]);

  return (
    <CheckoutContext.Provider
      value={{
        defaultDiscount,
        setAutoAppliedDiscount: changeAutoAppliedDiscount,
        requestAutoAppliedDiscount,
        autoAppliedDiscount,
        discount,
        atuDiscount,
        individualDiscount,
        isSelectedProductCanBeATU,
        autoTopUp,
        changeAutoTopUp,
        submitDiscountCode,
        changeATUDiscount,
        changeIndividualDiscount,
        changeDiscount,
        changeCodeError,
        discountLoading,
        changeDiscountLoading,
        code,
        changeCode,
        codeError,
        isATUEnabledForSelectedProduct,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};
