import App from "../App";
import {
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { Route } from "react-router-dom";
import React, { lazy } from "react";

import Steps from "../Steps";

export enum Routes {
  STEP1 = "step1",
  STEP2 = "step2",
  STEP3 = "step3",
  STEP4 = "step4",
  STEP5 = "step5",
  NOT_FOUND = "not_found",
}

const Step1Component = lazy(() => import("../forms/step1"));
const Step2Component = lazy(() => import("../forms/step2"));
const Step3Component = lazy(() => import("../forms/step3"));
const Step4Component = lazy(() => import("../forms/step4"));
const Step5Component = lazy(() => import("../forms/step5"));
const NotFound = lazy(() => import("../forms/NotFound"));

export const appRoutes = () => {
  return createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<App />}>
        <Route path={"/*"} element={<Steps />}>
          <Route path=":slug" element={<Step1Component />} />
          <Route path={Routes.STEP1} element={<Step1Component />} />
          <Route path={Routes.STEP2} element={<Step2Component />} />
          <Route path={Routes.STEP3} element={<Step3Component />} />
          <Route path={Routes.STEP4} element={<Step4Component />} />
          <Route path={Routes.STEP5} element={<Step5Component />} />
          <Route path={Routes.NOT_FOUND} element={<NotFound />} />
          <Route index element={<Step1Component />} />
        </Route>
      </Route>
    )
  );
};
